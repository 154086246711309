import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg"; // Ensure this path is correct
import supabase from "../../../services/supabaseClient"; // Adjust this path if needed

function Cover() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!email || !password || password !== confirmation) {
      setError("Please fill in all required fields and ensure passwords match.");
      return;
    }

    try {
      const { data, error } = await supabase.from("users").insert([
        {
          email,
          password, // Ideally, hash this password before storing
        },
      ]);

      if (error) {
        setError(error.message);
      } else {
        setSuccessMessage("Registration successful! You can now log in.");
        // Optionally, navigate after a delay or after confirming the success message
        setTimeout(() => {
          navigate("/authentication/sign-in");
        }, 3000); // Navigate after 3 seconds
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your details to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSignUp}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!error}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                value={confirmation}
                onChange={(e) => setConfirmation(e.target.value)}
              />
            </MDBox>
            {error && (
              <MDBox mb={2} textAlign="center">
                <MDTypography color="error">{error}</MDTypography>
              </MDBox>
            )}
            {successMessage && (
              <MDBox mb={2} textAlign="center">
                <MDTypography color="success">{successMessage}</MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Sign Up
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?&nbsp;
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
