import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      // Clear any user data from local storage or session storage
      localStorage.removeItem("user");

      // Optionally, you can also clear the session or tokens if any exist
      // e.g., sessionStorage.removeItem("authToken");

      // If there's any backend session to terminate, you can call it here
      // e.g., await supabase.auth.signOut();

      // Redirect the user to the sign-in page
      navigate("/authentication/sign-in");
    };

    performLogout();
  }, [navigate]);

  return <div>Logging out...</div>;
}

export default Logout;
