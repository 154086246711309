// OrdersOverview.js
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Calendar library
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Supabase client
import supabase from "services/supabaseClient";

// Set up the localizer by providing the moment (or globalize) Object
const localizer = momentLocalizer(moment);

function OrdersOverview() {
  const [events, setEvents] = useState([]);

  // Fetch reservation data from Supabase
  useEffect(() => {
    const fetchReservations = async () => {
      const { data, error } = await supabase
        .from("reservation")
        .select("reservation_date, start_time, end_time, vehicle_number");

      if (error) {
        console.error("Error fetching reservations:", error);
        return;
      }

      // Convert reservation data to calendar event format
      const formattedEvents = data.map((reservation) => {
        const reservationDate = new Date(reservation.reservation_date);
        const startTimeParts = reservation.start_time.split(":"); // Split time string (HH:MM:SS)
        const endTimeParts = reservation.end_time.split(":");

        // Create start and end Date objects by combining reservation date and time
        const start = new Date(
          reservationDate.getFullYear(),
          reservationDate.getMonth(),
          reservationDate.getDate(),
          parseInt(startTimeParts[0], 10), // Hours
          parseInt(startTimeParts[1], 10) // Minutes
        );

        const end = new Date(
          reservationDate.getFullYear(),
          reservationDate.getMonth(),
          reservationDate.getDate(),
          parseInt(endTimeParts[0], 10), // Hours
          parseInt(endTimeParts[1], 10) // Minutes
        );

        return {
          title: `Reserved - ${reservation.vehicle_number}`, // Event title (can be customized)
          start,
          end,
        };
      });

      setEvents(formattedEvents);
    };

    fetchReservations();
  }, []);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h4" fontWeight="medium">
          CALENDAR
        </MDTypography>
        <MDTypography variant="h6" fontWeight="medium">
          Look reservation information on the calendar
        </MDTypography>
        <MDBox mt={3} mb={2}>
          <Calendar
            localizer={localizer}
            events={events} // Use fetched events from Supabase
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
