import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import supabase from "../../../services/supabaseClient";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Verify() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      const query = new URLSearchParams(location.search);
      const access_token = query.get("access_token");

      if (access_token) {
        const { error } = await supabase.auth.verifyEmail(access_token);
        if (error) {
          setMessage("Error verifying email: " + error.message);
        } else {
          setMessage("Email verified successfully! Redirecting...");
          setTimeout(() => {
            navigate("/authentication/sign-in");
          }, 3000);
        }
      } else {
        setMessage("Invalid verification link.");
      }
      setLoading(false);
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      {loading ? (
        <MDTypography variant="h4" fontWeight="medium">
          Verifying...
        </MDTypography>
      ) : (
        <MDTypography variant="h4" fontWeight="medium">
          {message}
        </MDTypography>
      )}
      <MDButton onClick={() => navigate("/")} variant="contained" color="info" mt={2}>
        Go to Home
      </MDButton>
    </MDBox>
  );
}

export default Verify;
