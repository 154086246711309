import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import supabase from "services/supabaseClient"; // Make sure this path is correct

function PaymentForm() {
  const [formData, setFormData] = useState({
    amount: "",
    payment_method: "",
  });
  const [calculatedAmount, setCalculatedAmount] = useState("");
  const navigate = useNavigate();
  const reservationId = 1; // Replace with the actual reservation ID

  useEffect(() => {
    const fetchReservation = async () => {
      const { data, error } = await supabase
        .from("reservation")
        .select("start_time, end_time")
        .eq("id", reservationId);

      if (error) {
        console.error("Error fetching reservation:", error);
      } else if (data.length > 0) {
        const { start_time, end_time } = data[0];
        const startTime = new Date(`1970-01-01T${start_time}Z`);
        const endTime = new Date(`1970-01-01T${end_time}Z`);
        const durationInMs = endTime - startTime;
        const amount = (durationInMs / 100000) * 100; // Assuming the duration is in milliseconds
        setCalculatedAmount(`${amount} FRW`);
        setFormData({ ...formData, amount });
      }
    };

    fetchReservation();
  }, [reservationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data, error } = await supabase.from("payments").insert([formData]);

    if (error) {
      console.error("Error inserting payment:", error);
    } else {
      console.log("Payment inserted successfully:", data);

      // Update reservation table to set payment_status to "booked"
      const reservationUpdate = await supabase
        .from("reservation")
        .update({ payment_status: "booked" })
        .eq("id", reservationId);

      if (reservationUpdate.error) {
        console.error("Error updating reservation:", reservationUpdate.error);
      } else {
        console.log("Reservation updated successfully:", reservationUpdate.data);
        // Clear the form
        setFormData({
          amount: "",
          payment_method: "",
        });
        // Redirect to another page
        navigate("/booked");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={3}>
        <MDTypography variant="h4" fontWeight="medium" mb={2}>
          Payment Form
        </MDTypography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDInput
                name="amount"
                label="Amount"
                variant="standard"
                fullWidth
                value={calculatedAmount}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                name="payment_method"
                label="Payment Method"
                variant="standard"
                fullWidth
                value={formData.payment_method}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Submit Payment
              </MDButton>
            </Grid>
          </Grid>
        </form>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PaymentForm;
