import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import supabase from "services/supabaseClient";

function ReservationPage() {
  const [formData, setFormData] = useState({
    parking_space_id: "",
    reservation_date: "",
    start_time: "",
    end_time: "",
    vehicle_number: "",
    phone: "",
    email: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [availableSpaces, setAvailableSpaces] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [userId, setUserId] = useState(null); // State to hold user ID
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch logged-in user's ID
    const fetchUserId = async () => {
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error("Error fetching user:", error);
      } else if (data.user) {
        setUserId(data.user.id); // Set user ID
      } else {
        console.error("No user is logged in.");
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    // Fetch available parking spaces
    const fetchAvailableSpaces = async () => {
      const { data, error } = await supabase
        .from("parking")
        .select("place1, place2, place3")
        .eq("id", 1)
        .single();

      if (error) {
        console.error("Error fetching parking spaces:", error);
      } else {
        const spaces = [];
        if (!data.place1) spaces.push({ id: 1, name: "Space 1" });
        if (!data.place2) spaces.push({ id: 2, name: "Space 2" });
        if (!data.place3) spaces.push({ id: 3, name: "Space 3" });
        setAvailableSpaces(spaces);
      }
    };

    fetchAvailableSpaces();
  }, []);

  useEffect(() => {
    // Fetch unavailable dates
    const fetchUnavailableDates = async () => {
      if (formData.parking_space_id) {
        const { data, error } = await supabase
          .from("reservation")
          .select("reservation_date")
          .eq("parking_space_id", formData.parking_space_id);

        if (error) {
          console.error("Error fetching unavailable dates:", error);
        } else {
          const bookedDates = data.map((reservation) => reservation.reservation_date);
          setUnavailableDates(bookedDates);
        }
      }
    };

    fetchUnavailableDates();
  }, [formData.parking_space_id]);

  // Fetch all reservations
  useEffect(() => {
    const fetchReservations = async () => {
      const { data, error } = await supabase.from("reservation").select("*");
      if (error) console.error("Error fetching reservations:", error);
      else setReservations(data);
    };

    fetchReservations();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const checkForOverlappingReservation = async () => {
    const { reservation_date, start_time, end_time, parking_space_id } = formData;

    const { data, error } = await supabase
      .from("reservation")
      .select("*")
      .eq("parking_space_id", parking_space_id)
      .eq("reservation_date", reservation_date)
      .or(`start_time.lte.${end_time},end_time.gte.${start_time}`);

    if (error) {
      console.error("Error fetching reservations:", error);
      return false;
    }

    return data.length > 0;
  };

  const validateForm = () => {
    const { vehicle_number, phone } = formData;

    const vehicleRegex = /^[A-Za-z]{3}[0-9]{3}[A-Za-z]{1}$/;
    const phoneRegex = /^0\d{9}$/;

    if (!vehicleRegex.test(vehicle_number)) {
      setErrorMessage(
        "Vehicle Number must start with 3 alphabets, followed by 3 numbers, and end with 1 alphabet (e.g., RAB900E)."
      );
      return false;
    }
    if (!phoneRegex.test(phone)) {
      setErrorMessage("Phone Number must be 10 digits and start with 0.");
      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    const hasOverlap = await checkForOverlappingReservation();

    if (hasOverlap) {
      setErrorMessage("This parking space is already booked for the selected date and time.");
      setIsSubmitting(false);
      return;
    }

    // Include user_id in the data to insert
    const dataToInsert = {
      ...formData,
      user_id: userId, // Add user_id
      status: "Booked",
    };

    const { data, error } = await supabase.from("reservation").insert([dataToInsert]);

    if (error) {
      console.error("Error inserting reservation:", error);
      setSuccessMessage("");
    } else {
      console.log("Reservation inserted successfully:", data);
      setSuccessMessage("Reservation booked successfully!");
      setErrorMessage("");
      setFormData({
        parking_space_id: "",
        reservation_date: "",
        start_time: "",
        end_time: "",
        vehicle_number: "",
        phone: "",
        email: "",
      });
      setIsSubmitting(false);
      navigate("/reservation");
    }
  };

  const handleCancelReservation = async (id) => {
    const { error } = await supabase.from("reservation").delete().eq("id", id);

    if (error) {
      console.error("Error canceling reservation:", error);
      setErrorMessage("Failed to cancel reservation.");
      return;
    }

    setSuccessMessage("Reservation canceled successfully!");
    setReservations(reservations.filter((reservation) => reservation.id !== id));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={3}>
        <MDTypography variant="h4" fontWeight="medium" mb={2}>
          Booking Parking
        </MDTypography>
        {errorMessage && (
          <MDTypography variant="caption" color="error" mb={2}>
            {errorMessage}
          </MDTypography>
        )}
        {successMessage && (
          <MDTypography variant="caption" color="success" mb={2}>
            {successMessage}
          </MDTypography>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDBox mb={2}>
                <label>Parking Space</label>
                <select
                  name="parking_space_id"
                  value={formData.parking_space_id}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontSize: "16px",
                  }}
                >
                  <option value="">Select a parking space</option>
                  {availableSpaces.map((space) => (
                    <option key={space.id} value={space.id}>
                      {space.name}
                    </option>
                  ))}
                </select>
              </MDBox>
            </Grid>

            {/* Adjusting grid for responsiveness */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <MDInput
                  name="reservation_date"
                  label=""
                  variant="standard"
                  type="date"
                  value={formData.reservation_date}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  name="vehicle_number"
                  label="Vehicle Number"
                  variant="standard"
                  value={formData.vehicle_number}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  name="start_time"
                  label="Start Time"
                  variant="standard"
                  type="time"
                  value={formData.start_time}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  name="end_time"
                  label="End Time"
                  variant="standard"
                  type="time"
                  value={formData.end_time}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  name="phone"
                  label="Phone Number"
                  variant="standard"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  name="email"
                  label="Email Address"
                  variant="standard"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? "Booking..." : "Book Parking"}
              </MDButton>
            </Grid>
          </Grid>
        </form>
      </MDBox>

      <MDBox mt={5} mb={3}>
        <MDTypography variant="h4" fontWeight="medium" mb={2}>
          All Reservations
        </MDTypography>
        {reservations.length > 0 ? (
          reservations.map((reservation) => (
            <MDBox key={reservation.id} mb={2}>
              <MDTypography variant="body1">
                Parking Space ID: {reservation.parking_space_id}
              </MDTypography>
              <MDTypography variant="body1">
                Reservation Date: {reservation.reservation_date}
              </MDTypography>
              <MDTypography variant="body1">
                Start Time: {reservation.start_time}, End Time: {reservation.end_time}
              </MDTypography>
              <MDTypography variant="body1">
                Vehicle Number: {reservation.vehicle_number}
              </MDTypography>
              <MDTypography variant="body1">Phone: {reservation.phone}</MDTypography>
              <MDTypography variant="body1">Status: {reservation.status}</MDTypography>
              <MDButton
                variant="outlined"
                color="error"
                onClick={() => handleCancelReservation(reservation.id)}
              >
                Cancel Reservation
              </MDButton>
              <hr />
            </MDBox>
          ))
        ) : (
          <MDTypography variant="body2" color="textSecondary">
            No reservations found.
          </MDTypography>
        )}
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default ReservationPage;
