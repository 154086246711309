// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types"; // Import PropTypes

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// React-Leaflet components
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";

// Fix for default icon issue in Leaflet with React
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const parkingPlaces = [
  { lat: -1.56198, lng: 29.63726, name: "IPRC Musanze" },
  { lat: -1.56198, lng: 29.63726, name: "IPRC Musanze Parking Place" },
  { lat: -1.56198, lng: 29.63726, name: "My Location" },
  { lat: -1.95558, lng: 30.05941, name: "Parking Place 3" },
];

// Helper function to calculate distance between two coordinates
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
}

// Function to center the map around user's location
function SetViewOnClick({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
}

// Add prop validation for SetViewOnClick
SetViewOnClick.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number).isRequired, // Validate that coords is an array of numbers
};

function Billing() {
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Get user's current location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
      },
      (error) => {
        console.error("Error getting location: ", error);
      }
    );
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid
                container
                style={{ height: "100vh" }}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} md={12} xl={12} style={{ height: "100%" }}>
                  <MapContainer
                    center={[-1.5619814666231855, 29.637270266797586]} // Center map at IPRC Musanze
                    zoom={15}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    />
                    {/* Show user's current location if available */}
                    {userLocation && (
                      <Marker position={userLocation}>
                        <Popup>Your current location</Popup>
                        <SetViewOnClick coords={userLocation} />
                      </Marker>
                    )}

                    {/* Show parking places and estimate distance/time */}
                    {parkingPlaces.map((place, idx) => {
                      let distance = null;
                      let estimatedTime = null;

                      // Calculate distance and estimated time if userLocation is available
                      if (userLocation) {
                        distance = getDistanceFromLatLonInKm(
                          userLocation[0],
                          userLocation[1],
                          place.lat,
                          place.lng
                        ).toFixed(2); // Distance in km

                        // Estimate time based on walking speed (5 km/h) in minutes
                        estimatedTime = ((distance / 5) * 60).toFixed(0);
                      }

                      return (
                        <Marker key={idx} position={[place.lat, place.lng]}>
                          <Popup>
                            {place.name}
                            {distance && (
                              <>
                                <br />
                                Distance: {distance} km
                                <br />
                                Estimated Time: {estimatedTime} mins
                              </>
                            )}
                          </Popup>
                        </Marker>
                      );
                    })}
                  </MapContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
