import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import carParkImage from "assets/images/car_park.png";
import carImage from "assets/images/car.png";

// Supabase client
import supabase from "services/supabaseClient";

function Projects() {
  const [parkingData, setParkingData] = useState({
    place1: true, // Assume all spots are available initially
    place2: true,
    place3: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch parking data from 'parking' table (occupied status)
        const { data: parkingStatus, error: parkingError } = await supabase
          .from("parking")
          .select("place1, place2, place3")
          .eq("id", 1)
          .single();

        if (parkingError) throw parkingError;

        // Fetch reserved parking spaces from the 'reservation' table
        const { data: reservations, error: reservationError } = await supabase
          .from("reservation")
          .select("parking_space_id")
          .in("parking_space_id", [1, 2, 3]);

        if (reservationError) throw reservationError;

        // Initialize all parking spots as available
        const updatedParkingData = {
          place1: true,
          place2: true,
          place3: true,
        };

        // Update parking spots based on parking table (occupied status)
        if (parkingStatus.place1 === 1) updatedParkingData.place1 = false;
        if (parkingStatus.place2 === 1) updatedParkingData.place2 = false;
        if (parkingStatus.place3 === 1) updatedParkingData.place3 = false;

        // Update parking spots based on reservation data (reserved status)
        reservations.forEach((reservation) => {
          if (reservation.parking_space_id === 1) updatedParkingData.place1 = false;
          if (reservation.parking_space_id === 2) updatedParkingData.place2 = false;
          if (reservation.parking_space_id === 3) updatedParkingData.place3 = false;
        });

        setParkingData(updatedParkingData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data initially
    fetchData();

    // Polling: fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to determine item style based on availability
  const itemStyle = (available) => ({
    backgroundImage: `url(${carParkImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    margin: "10px",
    boxShadow: "0 40px 80px rgba(0,0,0,0.2)",
  });

  // Parking spaces data
  const spaces = [
    { name: "Space 1", available: parkingData.place1 },
    { name: "Space 2", available: parkingData.place2 },
    { name: "Space 3", available: parkingData.place3 },
  ];

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h4" gutterBottom>
            PARKING SPACE
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}></MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={3}>
        <Grid container spacing={2}>
          {spaces.map(
            (space, index) =>
              space.available !== undefined && (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <MDBox style={itemStyle(space.available)}>
                    {!space.available && (
                      <>
                        <img
                          src={carImage}
                          alt={space.name}
                          style={{
                            width: "350%",
                            height: "200%",
                            objectFit: "cover",
                            position: "absolute",
                            top: "60%",
                            left: "70%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                        <MDTypography
                          variant="body1"
                          fontWeight="bold"
                          color="white"
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                          }}
                        >
                          NOT AVAILABLE
                        </MDTypography>
                      </>
                    )}
                    <MDTypography
                      variant="body1"
                      color="white"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                      }}
                    >
                      {space.name}
                    </MDTypography>
                  </MDBox>
                </Grid>
              )
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Projects;
