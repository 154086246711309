/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton"; // Import the MDButton component

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import supabase from "services/supabaseClient";
import { useEffect, useState } from "react";

export default function useTableData() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from Supabase
    const fetchData = async () => {
      try {
        let { data: history, error } = await supabase
          .from("history")
          .select("id, place, entry_time, exit_time, duration"); // Include 'id' for deletion

        if (error) throw error;

        setData(history);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to delete a row
  const deleteRow = async (id) => {
    try {
      const { error } = await supabase.from("history").delete().eq("id", id); // Use the primary key to identify the row to delete

      if (error) throw error;

      // Update local state after deletion
      setData(data.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const Author = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Place", accessor: "place", width: "35%", align: "left" },
      { Header: "Entry Time", accessor: "entry_time", align: "left" },
      { Header: "Exit Time", accessor: "exit_time", align: "center" },
      { Header: "Parking Duration", accessor: "duration", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: data.map((item) => ({
      place: <Author name={item.place.toUpperCase()} />,
      entry_time: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.entry_time}
        </MDTypography>
      ),
      exit_time: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.exit_time}
        </MDTypography>
      ),
      duration: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.duration}
        </MDTypography>
      ),
      action: (
        <MDButton
          variant="outlined"
          color="error"
          size="small"
          onClick={() => deleteRow(item.id)} // Call deleteRow with the item's id
        >
          Delete
        </MDButton>
      ),
    })),
  };
}
