import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import supabase from "services/supabaseClient"; // Ensure this path is correct
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import bgImage from "assets/images/bg-sign-up-cover.jpeg"; // Ensure this path is correct
import CoverLayout from "layouts/authentication/components/CoverLayout";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Hook for redirection

  useEffect(() => {
    // Check if user data is already stored in local storage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      // If user data exists, navigate directly to the parking page
      navigate("/parking");
    }
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");

    if (!email || !password) {
      setError("Please fill in both email and password.");
      return;
    }

    try {
      // Query the 'users' table to check if the user exists and the password matches
      const { data, error: queryError } = await supabase
        .from("users")
        .select("id, password, email") // Adjust fields as needed
        .eq("email", email)
        .single(); // Expect a single user with this email

      if (queryError) {
        setError("Invalid email or password.");
      } else if (!data) {
        setError("User not found.");
      } else if (data.password !== password) {
        setError("Invalid email or password.");
      } else {
        // User exists and password matches
        // Store user information in local storage (excluding password)
        const { password, ...userData } = data;
        localStorage.setItem("user", JSON.stringify(userData));

        // Redirect to the parking page
        navigate("/parking");
      }
    } catch (err) {
      console.error("Unexpected error: ", err);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to log in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleLogin}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            {error && (
              <MDBox mb={2} textAlign="center">
                <MDTypography color="error" variant="caption">
                  {error}
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Sign In
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign Up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Login;
